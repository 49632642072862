<template>
  <ion-grid>
    <ion-row>
      <ion-col>
        <p v-if="state.showHelp">
          <HelpText :text="'line_intro'"></HelpText>
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-xs="12" :size-md="showStacked?12:4">
        <ion-button v-on:click="setLine('kuricrimp')" size="large" expand="block"
                    fill="outline"
                    :class="{ active : isChosen('kuricrimp')}"
                    class="mkc-button mkc-line mkc-line-kuricrimp">
          <img src="/assets/img/kuricrimp-logo.svg" alt="KuriCrimp OP">
        </ion-button>
      </ion-col>
      <ion-col size-xs="12" :size-md="showStacked?12:4">
        <ion-button v-on:click="setLine('kurikrimp')" size="large" expand="block"
                    fill="outline"
                    :class="{ active : isChosen('kurikrimp')}"
                    class="mkc-button mkc-line mkc-line-kurikrimp">
          <img src="/assets/img/kurikrimp-logo.svg" alt="KuriKrimp MyCrimp">

        </ion-button>
      </ion-col>
      <ion-col size-xs="12" :size-md="showStacked?12:4">
        <ion-button v-on:click="setLine('piranhaflex')" size="large" expand="block"
                    fill="outline"
                    :class="{ active : isChosen('piranhaflex')}"
                    class="mkc-button mkc-line mkc-line-piranhaflex"
        >
          <img src="/assets/img/piranhaflex-logo.svg" alt="PiranhaFlex Hydraulics">
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import {IonButton, IonGrid, IonRow, IonCol} from '@ionic/vue'
import HelpText from "@/components/HelpText";
import store from '@/store'

export default {
  name: "ChooseLine",
  components: {IonButton, IonGrid, IonRow, IonCol, HelpText},
  emits: ['itemSelected'],
  props: {showStacked: Boolean},
  data() {
    return {
      strings: store.strings,
      state: store.state,
    }
  },
  methods: {
    setLine(line) {
      store.setLine(line);
      this.$emit('itemSelected');
    },
    isChosen(line) {
      return (line === this.state.line);
    }
  }

}
</script>

<style scoped>

</style>
